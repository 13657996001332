import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel, Toggle } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { financingProductsActions } from '../../state/ducks/financingProducts';
import './styles.scss';
import { FormFinancingProducts } from './components';

class CreateFinancingProducts extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: undefined,
    name: '',
    rate: undefined,
    installments: undefined,
    period: undefined,
    type: '',
    bank_id: '',
  });

  componentDidMount() {
    const {
      fetchFinancingProducts,
    } = this.props;
    fetchFinancingProducts({
      pageSize: 0,
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  loadProduct = async (data) => {
    await this.setState(data);
  };

  getGridSettings = (openToggle) => {
    const {
      t, financingProducts: { list }, deleteFinancingProduct,
    } = this.props;

    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.name}`,
      },
      {
        id: 'installments', // Required because our accessor is not a string
        Header: t('installmentNumber'),
        accessor: d => `${d.installments}`,
      },
      {
        id: 'period', // Required because our accessor is not a string
        Header: t('period'),
        accessor: d => `${d.period}`,
      },
      {
        id: 'rate', // Required because our accessor is not a string
        Header: t('interestRate'),
        accessor: d => `${d.rate}%`,
      },
      {
        id: 'type', // Required because our accessor is not a string
        Header: t('type'),
        accessor: d => `${t(d.type)}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id}`,
        Cell: cellInfo => (
          <td>
            <button
              className="button is-info is-inverted is-centered"
              onClick={() => {
                // eslint-disable-next-line no-underscore-dangle
                const data = cellInfo.row._original;
                this.loadProduct(data);
                openToggle();
              }}
            >
              <i className="fas fa-edit mr-8px" aria-hidden="true" />
              {t('edit')}
            </button>
            <Link
              className="button is-danger is-inverted"
              to="/financing-products"
              onClick={() => deleteFinancingProduct({ data: { id: cellInfo.value } })}
            >
              <i className="fas fa-times mr-8px" aria-hidden="true" />
              {t('delete')}
            </Link>
          </td>
        ),
      }];
    return {
      columns,
      data: list,
    };
  };

  render() {
    const {
      t,
      auth,
      addFinancingProducts,
      showMessage,
      editFinancingProducts,
    } = this.props;
    const product = this.state;
    return (
      <Toggle>
        {({
          toggled,
          onToggle,
          openToggle,
          closeToggle,
        }) => {
          const { data } = this.getGridSettings(openToggle);
          return (
            <div className="dashboard">
              <div className="columns is-multiline">
                <div className="column is-full">
                  <Panel headingText={t('financingProducts')}>
                    {
                      toggled
                      && (
                        <Fragment>
                          <div className="columns is-multiline">
                            <div
                              className="column is-full-touch is-offset-half-desktop is-half-desktop is-right"
                            >
                              <button
                                className="button is-info is-outlined is-pulled-right"
                                onClick={() => {
                                  this.resetState();
                                  onToggle();
                                }}
                              >
                                <span className="icon is-small">
                                  <i className="fas fa-plus-circle" />
                                </span>
                                <span>{t('goBack')}</span>
                              </button>
                            </div>
                            <div className="column is-full">
                              <FormFinancingProducts
                                auth={auth}
                                t={t}
                                addFinancingProducts={addFinancingProducts}
                                showMessage={showMessage}
                                redirectBack={closeToggle}
                                data={product}
                                editFinancingProducts={editFinancingProducts}
                              />
                            </div>
                          </div>
                        </Fragment>
                      )
                    }
                    {!toggled && data
                      && (
                        <Fragment>
                          <FormFinancingProducts
                            auth={auth}
                            t={t}
                            addFinancingProducts={addFinancingProducts}
                            showMessage={showMessage}
                            redirectBack={closeToggle}
                            data={product}
                            editFinancingProducts={editFinancingProducts}
                          />
                        </Fragment>
                      )}
                  </Panel>
                </div>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

CreateFinancingProducts.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  financingProducts: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchFinancingProducts: PropTypes.func.isRequired,
  deleteFinancingProduct: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  addFinancingProducts: PropTypes.func.isRequired,
  editFinancingProducts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, financingProducts }) => (
  {
    auth,
    financingProducts,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions, ...clientsActions, ...providersActions, ...financingProductsActions,
  }),
  withNamespaces(),
)(CreateFinancingProducts);
