const START_FETCH = 'clients/START_FETCH';
const END_FETCH = 'clients/END_FETCH';
const END_ERROR = 'clients/END_ERROR';
const API_CALL = 'clients/API_CALL';
const GET_CLIENTS = 'clients/GET_CLIENTS';
const GET_CLIENTS_CONDITIONS = 'clients/GET_CLIENTS_CONDITIONS';
const SAVE_CLIENTS = 'clients/SAVE_CLIENTS';
const MESSAGE = 'clients/MESSAGE';
const APPROVE_INVOICE = 'clients/APPROVE_INVOICE';
const DEFER_INVOICE = 'clients/DEFER_INVOICE';
const SET_CLIENTS_CONDITIONS = 'clients/SET_CLIENTS_CONDITIONS';
const SET_CLIENT = 'clients/SET_CLIENT';
const GET_CLIENT = 'clients/GET_CLIENT';
const EDIT_USER = 'clients/EDIT_USER';
const CLIENTS_FILE_LOAD = 'clients/CLIENTS_FILE_LOAD';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  GET_CLIENTS,
  GET_CLIENTS_CONDITIONS,
  SET_CLIENTS_CONDITIONS,
  SAVE_CLIENTS,
  MESSAGE,
  APPROVE_INVOICE,
  DEFER_INVOICE,
  GET_CLIENT,
  EDIT_USER,
  SET_CLIENT,
  CLIENTS_FILE_LOAD,
};
