import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, Panel, TableSimple, } from '../../components';
import { cardActions } from '../../state/ducks/card';
import 'moment/locale/es';
import { TOAST_CONFIG } from '../../config/constants';

class Card extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    cycle_date: new Date(),
  });

  componentDidMount() {

  };

  getGridSettings = () => {
    const {
      t,
      card: { list },
      deleteCard,
      showMessage,
      history,
    } = this.props;

    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'numberTta', // Required because our accessor is not a string
        Header: t('numberTta'),
        accessor: d => d.number,
      },
      {
        id: 'expirationDate', // Required because our accessor is not a string
        Header: t('expirationDate'),
        accessor: d => d.due_pay,
      },
      {
        id: 'billingCycle', // Required because our accessor is not a string
        Header: t('billingCycle'),
        accessor: d => d.billing_cycle !== null ? d.billing_cycle.cycle : '',
      },
      {
        id: 'TtaValidation', // Required because our accessor is not a string
        Header: t('TtaValidation'),
        accessor: d => d.is_validate === '1' ? t('validCard') : t('notVerified'),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        show: false,
        accessor: d => d.number,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button className="button is-primary is-inverted"
                        onClick={() => {
                          deleteCard({
                            params: {
                              cardId: cellInfo.original.id,
                            },
                            callback: () => {
                              /*getCard({
                                param: {
                                  id: clientList.buyer[0].id,
                                }
                              });*/
                              showMessage({
                                message: t('deleteTta'),
                                config: TOAST_CONFIG.SUCCESS,
                              });
                              history.push('/dashboard');
                            }
                          });
                        }}
                >
                  <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                     style={{ color: 'red' }}/>
                  {t('delete')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        )
      },
    ];
    const data = list;
    const title = t('profileList');
    return {
      columns,
      data,
      title,
    };
  };

  render() {
    const {
      t,
      card: { loading },
    } = this.props;
    const {
      clientName
    } = this.props.location.state;

    const { columns, data } = this.getGridSettings();
    return (
      <div>
        {loading && <Loading/>}
        <Panel headingText={t('registeredCards', { name: clientName })}>
          {data
          && (
            <div>
              <TableSimple data={data} columns={columns}/>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

Card.propTypes = {
  t: PropTypes.func.isRequired,
  getCycleCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, clients, }) => (
  {
    card,
    clients,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
  }),
  withNamespaces(),
)(Card);