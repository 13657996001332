import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';

const CreatableMultiSelect = ({
  name,
  value,
  placeholder,
  onChange,
  helpText,
  label,
                                fieldClasses,
                                controlClasses,
                                icon = null,
                                iconPosition = null,
                                options = [],
                                noOptionsMessage = () => (''),
                                formatCreateLabel = () => (''),
                                getOptionLabel = () => (''),
                                getOptionValue = () => (''),
                                getNewOptionData = () => (''),
                                onInputChange,
                                inputValue,
                                isRequired,
                              }) => (
  <div className={`field ${fieldClasses}`}>
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor={name} className="label">{label} <label>{isRequired ? ' *' : ''}</label></label>
    <div className={`control ${controlClasses}`}>
      {
        <CreatableSelect
          className="basic-single"
          classNamePrefix="select"
          isMulti
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          noOptionsMessage={noOptionsMessage}
          formatCreateLabel={formatCreateLabel}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          getNewOptionData={getNewOptionData}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.querySelector('body')}
          onInputChange={onInputChange}
          inputValue={inputValue}
          defaultInputValue="sell"
        />
      }
      {
        !!icon && (
          <span className={`icon ${iconPosition}`}>
            <i className={`${icon}`} />
          </span>
        )
      }
    </div>
    <p className="help">{helpText}</p>
  </div>
);

CreatableMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  helpText: PropTypes.string,
  label: PropTypes.string,
  fieldClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  icon: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getNewOptionData: PropTypes.func,
  iconPosition: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
};

CreatableMultiSelect.defaultProps = {
  placeholder: null,
  helpText: null,
  label: null,
  fieldClasses: '',
  controlClasses: '',
  noOptionsMessage: () => (''),
  formatCreateLabel: () => (''),
  getOptionLabel: () => (''),
  getOptionValue: () => (''),
  getNewOptionData: () => (''),
  icon: null,
  iconPosition: null,
  options: [],
  value: [],
  onInputChange: () => {
  },
  inputValue: '',
  onChange: () => {
  },
};

export default withNamespaces()(CreatableMultiSelect);
