import { cardActions, cardTypes } from '../ducks/card';
import { TOAST_CONFIG } from '../../config/constants';

const cardData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.FETCH_CARD_DATA) {
    return;
  }

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'prepaid/get-data',
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          const {
            card, person, address, transactions,
          } = data;

          dispatch(cardActions.updateCardData({
            card,
            person,
            address,
            transactions,
          }));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const recharge = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.RECHARGE_CARD) {
    return;
  }

  const {
    data,
    callback = () => { },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'prepaid/recharge-Card',
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success } = responseData;
        if (success) {
          callback();
          dispatch(cardActions.showMessage({
            message: 'El saldo de su tarjeta ha sido recargado exitosamente',
            config: TOAST_CONFIG.INFO,
          }));
        } else {
          dispatch(cardActions.showMessage({
            message: data.data.message,
            config: TOAST_CONFIG.INFO,
          }));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const getCycleCard = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.GET_CYCLE_CARD) {
    return;
  }

  const {
    params: {
      bank = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `billing-cycles/get-billing-cycles?bank=${bank}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.saveCycleCard(data));
          //dispatch(webpayActions.token(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const registerPeriods = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.REGISTER_PERIODS) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'billing-cycles/register-periods',
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.saveCycleCard(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const getBillingCycles = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.GET_BILLING_CYCLES) {
    return;
  }

  const {
    params: {
      bank = '',
      month = '',
      year = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `billing-cycles/billing-cycle-periods?month=${month}&year=${year}&bank=${bank}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.getCycles(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const registerExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.REGISTER_EXECUTIVE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-banking-executive/register',
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.saveCycleCard(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const loadExecutivesFile = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.LOAD_EXECUTIVES_FILE) {
    return;
  }

  const {
    data: {
      file_executives,
    },
    callback = () => {},
    t = () => {},
  } = action.payload;

  const dataForm = new FormData();
  dataForm.append('file_executives', file_executives);
  const dataSend = dataForm;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-banking-executive/load-executives-file',
        data: dataSend,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(cardActions.saveExecutivesFile(response.data.data.executives));
          callback(response.data.data.executives);
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
}

const getExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.GET_EXECUTIVE) {
    return;
  }

  const {
    params: {
      owner = '',
      status = 1,
      deleted = 0
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-banking-executive/get-executives?owner=${owner}&status=${status}&deleted=${deleted}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(cardActions.getCycles(data));
          callback(data);
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const getCard = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.GET_CARD) {
    return;
  }

  const {
    params: {
      person = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `pci-booking/get-payment-person?id=${person}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.getCycles(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const deleteCard = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.DELETE_CARD) {
    return;
  }

  const {
    params: {
      cardId = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `pci-booking/delete-payment-person?id=${cardId}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const assistCard = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.ASSIST_CARD) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b/exists-assist-card',
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
          dispatch(cardActions.getAssistCard(response.data.data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const editBillingCycle = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.EDIT_BILLING_CYCLES) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `billing-cycles/update-billing-cycle-periods`,
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const getPeriodBilling = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.GET_PERIOD_BILLING) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `billing-cycles/get-period-billing?billing_cycle=${id}`,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.getCycles(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const updateBillingPaymentPerson = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.UPDATE_BILLING_PAYMENT_PERSON) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'pci-booking/update-billing-payment-person',
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(cardActions.saveCycleCard(data));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

export default [cardData, recharge, getCycleCard, registerPeriods, getBillingCycles, registerExecutive, getExecutive, getCard, deleteCard, assistCard, editBillingCycle, loadExecutivesFile, getPeriodBilling, updateBillingPaymentPerson];

