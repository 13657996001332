import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';


const SubMenu = (props) => {
  const {
    t, items, open, pathname,
  } = props;
  const classOpen = open ? 'open' : '';

  return (
    <ul className={`subMenu animated pulse ${classOpen}`}>
      {items.map((value) => {
        const active = pathname === value.url ? 'active' : '';
        if (value.isHidden) {
          return null;
        }
        return (
          <li key={value.name} className={`${value.activeClass} ${active}`}>
            <Link to={value.url}>
              <span>
                {t(value.name)}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

SubMenu.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

SubMenu.defaultProps = {
  items: [],
};

export default withNamespaces()(SubMenu);
