import { clientsActions, clientsTypes } from '../ducks/clients';

const fetchClients = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.GET_CLIENTS) {
        return;
    }

    const {
        params: {
            id = '',
            related = false,
        } = {},
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `/b2b/clients?provider=${id}&related=${related}`,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { success, data } }) => {
                if (success) {
                    callback(data);
                    dispatch(clientsActions.saveClients(data));
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const fetchClient = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_CLIENT) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/client?client=${id}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(clientsActions.setClient(data));
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchClientsConditions = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.GET_CLIENTS_CONDITIONS) {
        return;
    }

    const {
        params: {
            id = '',
        } = {},
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `/b2b/clients-with-conditions?provider=${id}`,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { success, data } }) => {
                if (success) {
                    callback(data);
                    dispatch(clientsActions.setClientsConditions(data));
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const sendResponse = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.APPROVE_INVOICE) {
        return;
    }
    const {
        data: {
            transaction_id,
            approved,
            user_id,
            role_id,
            note,
            provider,
            file_sii,
            file_pass,
        },
        data,
        callback = () => {
        },
    } = action.payload;

    const dataForm = new FormData();
    if (file_sii) {
        dataForm.append('transaction_id', transaction_id);
        dataForm.append('approved', approved);
        dataForm.append('user_id', user_id);
        dataForm.append('role_id', role_id);
        dataForm.append('note', approved === false ? note : '');
        dataForm.append('provider', provider);
        dataForm.append('file_pass', file_pass);
        dataForm.append('file_sii', file_sii);
    }

    const dataSend = file_sii ? dataForm : data;

    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/b2b/approve',
                data: dataSend,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: (response) => {
                if (response.data.success === true) {
                    callback(response.data.data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const defer = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.DEFER_INVOICE) {
        return;
    }

    const {
        data,
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/b2b/defer',
                data,
            },
            authorization: false,
            onStart: clientsActions.startFetch,
            onComplete: (response) => {
                if (response.data.success) {
                    callback(response.data.data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const editUser = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.EDIT_USER) {
        return;
    }

    const {
        data,
        callback = () => {},
    } = action.payload;

    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/user/update-buyer-seller-information',
                data,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { data, success } }) => {
                if (success) {
                    callback(data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const clientsFileLoad = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.CLIENTS_FILE_LOAD) {
        return;
    }

    const {
        data,
        data: {
            bank_id,
            role,
            users_file,
        },
        callback = () => {
        },
        t = () => {
        },
    } = action.payload;

    const dataForm = new FormData();
    if(users_file){
        dataForm.append('bank_id', bank_id);
        dataForm.append('role', role);
        dataForm.append('users_file', users_file);
    }
    const dataSend = users_file ? dataForm : data;

    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
            method: 'POST',
            url: '/api/users-file-load',
            data: dataSend,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: (response) => {
                dispatch(clientsActions.setClientsConditions(response.data.data));
                callback(response.data.data);
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

export default [fetchClients, fetchClientsConditions, sendResponse, defer, fetchClient, editUser, clientsFileLoad];