const START_FETCH = 'item/START_FETCH';
const END_FETCH = 'item/END_FETCH';
const API_CALL = 'item/API_CALL';
const MESSAGE = 'item/MESSAGE';
const FETCH_ITEMS = 'item/FETCH_ITEMS';
const FETCH_CITIES = 'item/FETCH_CITIES';
const FETCH_REGIONS = 'item/FETCH_REGIONS';
const FETCH_COMMUNES = 'item/FETCH/REGIONS';
const UPDATE_ITEMS = 'item/UPDATE_ITEMS';
const FETCH_CATEGORIES = 'item/FETCH_CATEGORIES';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_ITEMS,
  FETCH_CITIES,
  FETCH_REGIONS,
  FETCH_COMMUNES,
  UPDATE_ITEMS,
  FETCH_CATEGORIES,
};
