import React, { Component } from 'react';
import { connect } from 'react-redux';

const withPermissions = (WrappedComponent) => {
  class AddPermissions extends Component {
    state = {};

    userHasRoles = (rolesToCheck) => {
      const { roles } = this.props;
      if (Array.isArray(rolesToCheck)) {
        return rolesToCheck.some(role => roles.map(r => r.name)
          .includes(role));
      }
      return roles.map(r => r.name)
        .includes(rolesToCheck);
    };

    hasAccessTo = (pathsToCheck) => {
      const { paths } = this.props;
      if (Array.isArray(pathsToCheck)) {
        return pathsToCheck.some(path => paths.includes(path));
      }
      return paths.includes(pathsToCheck);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          userHasRoles={this.userHasRoles}
          hasAccessTo={this.hasAccessTo}/>
      );
    }
  }

  AddPermissions.displayName = `withPermissions(${WrappedComponent.displayName || WrappedComponent.name})`;

  const mapStateToProps = ({ auth: { user: { b2b: { roles, paths } } } }) => ({
    roles,
    paths,
  });

  return connect(mapStateToProps, null)(AddPermissions);
};

export default withPermissions;
