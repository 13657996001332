import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { leadActions } from '../../state/ducks/lead';
import { bankActions } from '../../state/ducks/bank';
import { roleActions } from '../../state/ducks/role';
import { documentTypeActions } from '../../state/ducks/documentType';
import {
  Panel,
  TableSimple,
  Toggle,
  Loading,
} from '../../components';
import Modal from '../../components/Modal';

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
  });

  componentDidMount() {
    const {
      fetchLeads,
      fetchBanks,
      fetchDocumentTypes,
      fetchRoles,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
    } = this.props;
    fetchDocumentTypes({});
    fetchRoles({
      params: {
        type: 'external',
      },
    });
    fetchBanks({
      callback: fetchLeads({
        params: {
          role_id: type_user === 'issuing_bank' ? 'buyer' : 'seller',
        },
      }),
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      lead: { list: LeadList },
      deleteLead,
      bank: { list: bankList },
      documentType: { list: documentTypeList },
      auth: {
        user: {
          b2b: { type_user },
        },
      },
      history,
      fetchLeads,
      role: { list: roles },
    } = this.props;
    let title = null;
    let pathname = null;
    switch (type_user) {
      case 'issuing_bank':
        title = t('clients');
        pathname = '/create-customer';
        break;
      case 'acquiring_bank':
        title = t('providers');
        pathname = '/create-provider';
        break;
      default:
        title = '';
    }
    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.lead_name} `,
      },
      {
        id: 'fiscaldocument', // Required because our accessor is not a string
        Header: t('fiscaldocument'),
        accessor: d => `${documentTypeList.filter(documentType => documentType.id === d.document_type)[0] && documentTypeList.filter(documentType => documentType.id === d.document_type)[0].document_type} ${d.document_number} `,
      },
      {
        id: 'address', // Required because our accessor is not a string
        Header: t('address'),
        accessor: d => `${d.address} `,
      },
      {
        id: 'referrer', // Required because our accessor is not a string
        Header: t('referrer'),
        accessor: d => `${bankList.filter(bank => bank.id === d.referrer_id)[0] && bankList.filter(bank => bank.id === d.referrer_id)[0].idPerson.name}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
              <tr>
                <td>
                  <button
                    className="button is-success is-inverted"
                    onClick={() => history.push({
                      pathname,
                      state: {
                        name: cellInfo.original.lead_name,
                        documentType: documentTypeList
                          .map((documentTypeMap, index) => ({ ...documentTypeMap, index }))
                          .filter(documentType => documentType.id
                            === cellInfo.original.document_type)[0]
                          .index,
                        documentNumber: cellInfo.original.document_number,
                        address: cellInfo.original.address,
                        lead_id: cellInfo.original.id,
                        lead: true,
                      },
                    })}
                  >
                    <i className="far fa-edit mr-8px" aria-hidden="true" />
                    {t('create')}
                  </button>
                  <button
                    className="button is-danger is-inverted"
                    onClick={() => {
                      deleteLead({
                        params: {
                          id: cellInfo.original.id,
                        },
                        callback: () => fetchLeads({
                          params: {
                            role_id: type_user === 'issuing_bank' ? 'buyer'
                              : 'seller',
                          },
                        }),
                      });
                    }}
                  >
                    <i className="far fa-reject mr-8px" aria-hidden="true" />
                    {t('reject')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = documentTypeList.length > 0 ? LeadList : [];
    return {
      columns,
      data,
      title,
    };
  };

  updateRoute = (onToggle) => {
    const {
      editLead,
      fetchLeads,
    } = this.props;
    const { id, name } = this.state;
    const data = {
      id,
      path: name,
    };
    editLead({
      data,
      callback: () => {
        fetchLeads({});
        onToggle();
        this.resetState();
      },
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      lead: { loading: leadLoading },
      bank: { loading: bankLoading },
      documentType: { loading: documentTypeLoading },
      role: { loading: roleLoading },
    } = this.props;
    const loading = leadLoading || bankLoading || documentTypeLoading || roleLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle} />
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns} />
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Leads.propTypes = {
  t: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  editLead: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  lead: PropTypes.shape().isRequired,
  bank: PropTypes.shape().isRequired,
  role: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  documentType: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};


const mapStateToProps = ({
  auth,
  lead,
  bank,
  documentType,
  role,
}) => ({
  auth,
  lead,
  bank,
  documentType,
  role,
});
export default compose(
  connect(mapStateToProps, {
    ...leadActions,
    ...bankActions,
    ...documentTypeActions,
    ...roleActions,
  }),
  withNamespaces(),
)(Leads);
