import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { providersActions } from '../../state/ducks/providers';

class CustomerCode extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    codes: [],
  });

  componentDidMount() {
    const {
      auth: { user: { user_id, b2b: { roles } } },
      providerCommerceCode
    } = this.props;

    providerCommerceCode({
      params: {
        user: user_id,
        role: roles[0].id
      },
      callback: (response) => {
        this.setState({ codes: response.commerce_codes });
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
    } = this.props;
    const { codes } = this.state;
    let columns = null;
    let data = null;
    let title = null;

    columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'commerceCode',
        Header: t('commerceCode'),
        width: 280,
        accessor: d => `${d.commerce_code}`,
      },
      {
        id: 'terminal', // Required because our accessor is not a string
        Header: t('eCommerceTerminal'),
        accessor: d => `${d.terminal}`,
      },
      {
        id: 'currency_id', // Required because our accessor is not a string
        Header: t('currency'),
        width: 180,
        accessor: d => d.currency_id !== null ? `${d.currency_id}` : '',
      },
      {
        id: 'description',
        Header: t('description'),
        accessor: d => `${d.description}`,
      },
    ];
    data = codes;

    return {
      columns,
      data,
      title,
    };
  };

  reloadCodes = () => {
    const {
      auth: { user: { user_id, b2b: { roles } } },
      providerCommerceCode,
    } = this.props;
    providerCommerceCode({
      params: {
        user: user_id,
        role: roles[0].id
      },
      callback: (response) => {
        this.setState({ codes: response.commerce_codes });
      },
    });
  };

  render() {
    const { t } = this.props;
    const { columns, data } = this.getGridSettings();

    return (
      <Panel headingText={t('affiliateNumber')}>
        <div className="column">
          <button
            className="button is-info"
            style={{ marginBottom: '1em' }}
            onClick={() => {
              this.reloadCodes();
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-sync-alt"/>
            </span>
            <p>{t('updateInfo')}</p>
          </button>
          <TableSimple data={data} columns={columns}/>
        </div>
      </Panel>
    );
  }

}

const mapStateToProps = ({ auth, providers }) => (
  {
    auth,
    providers,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...providersActions,
    ...authActions,
  }),
  withNamespaces(),
)(CustomerCode);