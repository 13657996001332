import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const stats = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.stats,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_RELATED_USERS: {
      const { payload } = action;
      return {
        ...state,
        relatedUsers: payload,
      };
    }
    case types.UPDATE_RELATED_ASSIST: {
      const { payload } = action;
      return {
        ...state,
        assist: payload,
      };
    }
    case types.UPDATE_STATS: {
      const {
        buyer_total, active_buyer_total, deferred_buyer_total, buyers,
        seller_total, active_seller_total, paid_seller_total, sellers,
        next_payment, next_payment_crc, next_payment_usd, total_debt_amount, total_debt_amount_crc,
        total_debt_amount_usd, total_owed_amount, graph_data, account_balance,
        pending_amount, paid_amount, financed_amount, paid_total, invoice_total, total_paid,
        payment_request_pending, total_operators, total_users, total_active_users, total_amount_approved_crc,
        total_amount_approved_usd, total_pending_invoices, total_amount_paid_crc, total_amount_paid_usd,
        inactive_providers, inactive_customers, total_paid_clients_crc, total_paid_clients_usd,
        total_paid_clients_month_usd, total_paid_clients_month_crc, total_pending_invoice_usd,
        total_pending_invoice_crc, paid_total_crc, paid_total_usd, total_owed_amount_crc, total_owed_amount_usd,
        total_paid_crc, total_paid_usd,
      } = action.payload;
      return {
        ...state,
        buyer_total,
        active_buyer_total,
        deferred_buyer_total,
        buyers,
        seller_total,
        active_seller_total,
        paid_seller_total,
        sellers,
        next_payment,
        next_payment_crc,
        next_payment_usd,
        total_debt_amount,
        total_debt_amount_crc,
        total_debt_amount_usd,
        total_owed_amount,
        graph_data,
        account_balance,
        pending_amount,
        paid_amount,
        financed_amount,
        paid_total,
        invoice_total,
        total_paid,
        paid_total_crc,
        paid_total_usd,
        payment_request_pending,
        total_operators,
        total_users,
        total_active_users,
        total_amount_approved_crc,
        total_amount_approved_usd,
        total_pending_invoices,
        total_amount_paid_crc,
        total_amount_paid_usd,
        inactive_providers,
        inactive_customers,
        total_paid_clients_crc,
        total_paid_clients_usd,
        total_paid_clients_month_usd,
        total_paid_clients_month_crc,
        total_pending_invoice_usd,
        total_pending_invoice_crc,
        total_owed_amount_crc,
        total_owed_amount_usd,
        total_paid_crc,
        total_paid_usd,
      };
    }
    default:
      return state;
  }
};

export default stats;
