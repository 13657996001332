import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextArea } from '../../../../components';
import TextInput from '../../../../components/TextInput';

const FormReject = (props) => {
  const {
    t,
    onSubmit,
    handleInputChange,
    data,
    isEdit,
    handleFileChange,
    file,
    binaryFile,
    type,
    type_user,
    password,
  } = props;
  const { rejectReason } = data;
  return (
    <Panel headingText={/*type_user === 'seller' ?*/ t('rejectReason') /*: t('digitalCertificate')*/}>
      <div className="FormLogin">
        <Form
          className="column is-full"
          submitText={type === 'delete' ? t('invoiceReject') : t('approveInvoice')}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
          disabled={!(rejectReason !== '')}
          buttonClasses={`mr-8px ${isEdit ? '' : ' is-hidden'}`}
        >
          <div style={{ display: (type_user === 'seller' ? 'none' : 'none') }}>
            <TextInput
              name="file"
              value={file}
              fileName={binaryFile !== '' ? binaryFile.name : binaryFile}
              onChange={handleFileChange}
              placeholder={t('digitalCertificate')}
              type="file"
              fieldClasses="file has-name"
              labelClasses="file-label"
              controlClasses="is-expanded has-icons-left file-label"
              inputClasses="has-text-right file-input"
              iconPosition="is-left"
              icon="fas fa-file-upload fa-2x"
              band={true}
            />
            <br/>
            <TextInput
              name="password"
              label={t('passwordDigitalCertificate')}
              placeholder={t('password')}
              type="password"
              onChange={handleInputChange}
              value={password}
            />
          </div>
          {/*
            <TextInput
              name="file"
              label={t('SelectDocumentType')}
              value={file}
              fileName={fileName}
              onChange={handleFileChange}
              // placeholder="Suba un documento"
              type="file"
              // fieldClasses="file has-name"
              //  labelClasses="file-label"
              //  controlClasses="is-expanded has-icons-left file-label"
              //  inputClasses="has-text-right "
              //  iconPosition="is-left"
              //  icon="fas fa-upload"
            />
          */}
          <div style={{ display: (type === 'delete' ? '' : 'none') }}>
            <TextArea
              name="rejectReason"
              value={rejectReason}
              onChange={handleInputChange}
              label={t('rejectReason')}
              placeholder={t('rejectReason')}
              controlClasses="is-expanded"
              maxLength="120"
              disabled={!isEdit}
            />
          </div>
          <br/>
        </Form>
      </div>
    </Panel>
  );
};

FormReject.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    rejectReason: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormReject);
